import React from 'react';

//import Image from 'react-bootstrap/Image';
import "./styles/events.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';

class Events extends React.Component {

    componentDidMount() {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    }
    componentDidUpdate() {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    }
    render() {
        return (
            <ContentWrapper header="Events">
                <div className="fb-page" data-href="https://www.facebook.com/Vernon-Multipurpose-Center-VMPC-1478940205727168/" data-tabs="events" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Vernon-Multipurpose-Center-VMPC-1478940205727168/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Vernon-Multipurpose-Center-VMPC-1478940205727168/">Vernon Multipurpose Center  VMPC</a></blockquote></div>
            </ContentWrapper>
        );
    }
}

export default Events;