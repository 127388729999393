import React from 'react';
import "./styles/donate.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function Donate() {
    return (
        <ContentWrapper header="Donate">
            <div className="donate">
                <p>
                    For donation infomation, email Correspondence Secretary, <a href="mailto:jacquelinebrowne103@yahoo.com?subject=Donation Question">Jacqueline Browne</a>.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default Donate;