import React from 'react';
import "./styles/contactUs.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function ContactUs() {
    return (
        <ContentWrapper header="Contact us">
            <div className="contact-us">
                <h3>Location</h3>
                <iframe className="contact-us__map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12376.636685157995!2d-94.6596755!3d39.1483612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2c1a34fdc96bc63a!2sVernon%20Multi%20Purpose%20Center!5e0!3m2!1sen!2sus!4v1612283154325!5m2!1sen!2sus" title="Vernon Multipurpose Center Map" width="600" height="450" frameBorder="0" style={{ border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </ContentWrapper>
    );
}

export default ContactUs;