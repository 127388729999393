import React from 'react';
import Card from 'react-bootstrap/Card';
import "./styles/boardMembers.css";
import { withRouter } from "react-router-dom";
import ContentWrapper from '../contentWrapper/ContentWrapper'

const members = [
    {
        name: "Cedric Patton",
        title:"President"
    },
    {
        name: "Luther Smith",
        title: "Vice President"
    },
    {
        name: "Brenda Smith",
        title: "Treasurer"
    },
    {
        name: "Hazel Davis",
        title: "Recording Secretary"
    },
    {
        name: "Jacqueline Browne",
        title: "Corresponding Secretary"
    },
    {
        name: "Joyce White",
        title: "Sergeant of Arms"
    },
    {
        name: "Betty Harris",
        title: ""
    },
    {
        name: "Willetta Jennings",
        title: ""
    },
    {
        name: "Helen Beteet",
        title: ""
    },
    {
        name: "Betty Adams",
        title: ""
    },
    {
        name: "Betty Ewell",
        title: ""
    },
    {
        name: "Larry C. Caldwell Sr.",
        title: ""
    },
    {
        name: "Clarence E. Brown",
        title: ""
    }
];

class BoardMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    Navigate = (to) => {
        this.props.history.push(to);
    }
    getCard = (member,index) => {
        const imagesBase = process.env.PUBLIC_URL + '/images/';
        const noPhotosImage = `${imagesBase}/NoPersonPhoto.png`;
        return (
            <li className="board-members__member" key={ index}>
                <Card style={{ width: '12rem' }}>
                    <Card.Img variant="top" src={noPhotosImage} />
                    <Card.Body>
                        <Card.Title>{member.name}</Card.Title>
                        <Card.Text>{member.title}</Card.Text>
                    </Card.Body>
                </Card>
            </li>
        );
    }
    render() {
        return (
            <ContentWrapper header="Board Members">
                <ul className="board-members">
                    {members.map((member,i) => this.getCard(member,i))}
                </ul>
            </ContentWrapper>
        );
    }
}

export default withRouter(BoardMembers);