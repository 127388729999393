import React from 'react';
import './App.css';
import RouterContent from './RouterContent'
import ScrollToTop from './ScrollToTop'
import { BrowserRouter as Router} from "react-router-dom";
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalImageUrl: ""
        }
    }

    
    render() {
        return (
            <div className="App">
                <Router>
                    <ScrollToTop/>
                    <RouterContent/>
                </Router>
            </div>
        );
    }
}

export default App;
